var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "company-label"
  }, [_c('span', {
    staticClass: "company-name"
  }, [_vm._v(" " + _vm._s(_vm.data.name || '') + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }