import { ref } from 'vue';
import useCRMApi from '@/hooks/crm/useCRMApi';
import ApiClient from '@/services/api';

// TODO: possibly merge with useCompanyManagement
const useCompanyDetails = (id) => {
  const company = ref({});

  const type = ref(null);
  const fetchFailed = ref(false);

  const services = ref([]);

  const urls = {
    fetch: `api/crm/company/${id}/edit`,
  };

  const fetchDetails = () => {
    ApiClient
      .get(urls.fetch)
      .then(({ data }) => {
        company.value = data.data;

        if (data.data?.company_type?.PROVIDER_TYPE === true) type.value = 'provider';
        if (data.data?.company_type?.CLIENT_TYPE === true) type.value = 'client';

        company.value.industries_rich = company.value.industries;
        company.value.regions_rich = company.value.regions;

        company.value.industries = company.value.industries.map((industry) => industry.id);
        company.value.regions = company.value.regions.map((branch) => branch.id);
      }).catch(() => {
        fetchFailed.value = true;
      });
  };

  fetchDetails();

  const fetchServices = () => {
    useCRMApi()
      .fetchCompanyServices()
      .then(({ data }) => {
        services.value = data.data;
      })
      .catch(() => {});
  };

  return {
    company,
    type,
    services,
    fetchFailed,

    fetchServices,
  };
};

export default useCompanyDetails;
