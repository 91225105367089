<template>
  <span class="company-label">
    <span class="company-name">
      {{ data.name || '' }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'CompanyLabel',
  props: {
    data: {
      type: Object,
      default: () => ({
        default: true,
        id: 0,
        name: 'company',
      }),
    },
  },
};
</script>

<style lang="sass" scoped>
.user-label
  display: flex
  align-items: center
</style>
