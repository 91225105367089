import showToast from '@/libs/toasts';
import {
  CreateContactSuccess,
  DeleteContactError,
  DeleteContactSuccess,
  UpdateContactError,
  UpdateContactSuccess,
} from '@/libs/toasts/feedback/module/crm';
import { computed, ref, watch } from 'vue';
import useCRMApi from './useCRMApi';

const useCompanyContact = (companyId) => {
  const remoteAddresses = ref([]);
  const localAddresses = ref([]);
  const addresses = computed(() => [...remoteAddresses.value, ...localAddresses.value]);

  // id of tab with editing address
  const editingAddress = ref(0);

  watch(localAddresses, () => {
    // ugly watcher, but fiddling with event loop
    // was neccessary to properly update current tab
    // FIXME find a better way?
    setTimeout(() => {
      editingAddress.value = addresses.value.length - 1;
    }, 0);
  });

  const fetch = () => {
    useCRMApi()
      .fetchCompanyContact(companyId)
      .then(({ data }) => {
        remoteAddresses.value = data.data;
      })
      .catch(() => {});
  };

  const storeContactPersons = (addressId, persons) => {
    const newPersons = persons;

    useCRMApi()
      .createManyCompanyContactPersons({
        address: newPersons,
        company_address_id: addressId,
      })
      .then(() => showToast(UpdateContactSuccess))
      .catch(() => showToast(UpdateContactError));
  };

  const createCompanyAddress = (obj) => {
    useCRMApi()
      .createCompanyContact({
        ...obj,
        company_id: companyId,
        is_primary: false,
      })
      .then(({ data }) => {
        if (obj.contacts.length > 0) return storeContactPersons(data?.data?.id, obj.contacts);
        return showToast(CreateContactSuccess);
      })
      .catch(() => showToast(UpdateContactError));
  };

  const updateCompanyAddress = (obj) => {
    useCRMApi()
      .updateCompanyContact({
        ...obj,
        company_id: companyId,
        is_primary: false,
      })
      .then(({ data }) => {
        if (obj.contacts.length > 0) return storeContactPersons(data?.data?.id, obj.contacts);

        return showToast(UpdateContactSuccess);
      })
      .catch(() => showToast(UpdateContactError));
  };

  const saveCurrentAddress = () => {
    const obj = addresses.value[editingAddress.value];

    if (!obj) return null;
    if (obj.id) return updateCompanyAddress(obj);

    return createCompanyAddress(obj);
  };

  const createNewAddress = () => {
    localAddresses.value.push({
      contacts: [],
    });
  };

  const deletePerson = (location, person) => {
    useCRMApi()
      .deleteCompanyContact(person.id)
      .then(() => {
        showToast(DeleteContactSuccess);
        remoteAddresses.value = remoteAddresses.value.map((address) => {
          if (address.id !== location.id) return address;

          return {
            ...location,
            contacts: location.contacts.filter((contact) => contact.id !== person.id),
          };
        });
      })
      .catch(() => showToast(DeleteContactError));
  };

  const setPrimary = (location) => {
    useCRMApi()
      .setPrimaryCompanyContact(location.id)
      .then(() => {
        showToast(DeleteContactSuccess);
      })
      .catch(() => showToast(DeleteContactError));
  };

  return {
    remoteAddresses,
    addresses,
    editingAddress,

    fetch,
    createNewAddress,
    saveCurrentAddress,
    deletePerson,
    setPrimary,
  };
};

export default useCompanyContact;
