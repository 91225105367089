<template>
  <widget-card>
    <template #header>
      <div class="profile">
        <div class="details">
          <content-placeholders v-if="!companyFetched" rounded>
            <content-placeholders-heading :img="false"/>
          </content-placeholders>

          <div
            v-if="companyFetched && company.company_type && company.company_type.CLIENT_TYPE"
            class="company-type"
            >
            Klient
          </div>

          <div
            v-if="companyFetched && company.company_type && company.company_type.PROVIDER_TYPE"
            class="company-type"
            >
            Dostawca
          </div>

          <div class="name" v-if="companyFetched">
            <company-label :data="company" />
          </div>
        </div>

        <widget-card-controls
          editPermission="crm.company.update"
          deletePermission="crm.company.destroy"
          @edit="() => $router.push({ name: `crm.${type}.settings` })"
          @delete="deleteCompany"
        />

      </div>
    </template>

    <div class="action-buttons">

      <content-placeholders class="mt-2" v-if="!companyFetched" rounded>
        <content-placeholders-text :lines="7"/>
      </content-placeholders>

      <table v-else>
        <tbody>
        <tr>
          <td>status</td>
          <td>
            <status-badge :status_id="company.company_status_id" />
          </td>
        </tr>
        <tr>
          <td>region</td>
          <td v-if="company.regions_rich">
            {{ company.regions_rich.map((region) => region.shortcut).join(', ') }}
          </td>
          <td v-else>
            -
          </td>
        </tr>
        <tr>
          <td>branża</td>
          <td v-if="company.industries_rich">
            {{ company.industries_rich.map((industry) => industry.name).join(', ') }}
          </td>
          <td v-else>-</td>
        </tr>
        <tr>
          <td>www</td>
          <td>
            <a v-if="company.website" :href="`//${company.website}`" target="_blank">
              {{ company.website }}
            </a>
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>nip</td>
          <td>{{ nip }}</td>
        </tr>
        <tr>
          <td>regon</td>
          <td>{{ company.regon }}</td>
        </tr>
        <tr>
          <td>krs</td>
          <td>{{ company.krs || '-' }}</td>
        </tr>
        </tbody>
      </table>

      <div class="protected-workplace-spacer" v-if="company.protected_workplace"></div>
      <div class="protected-workplace" v-if="company.protected_workplace">
        <feather-icon
          class="icon"
          icon="ShieldIcon"
          size="21"
        />
        <div class="text">
          Miejsce pracy chronionej
        </div>
      </div>

    </div>
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import StatusBadge from '@/components/ui/status-badge/Company.vue';
import formatDate from '@/libs/date-formatter';
import { computed, inject } from 'vue';
import useCompanyManagement from '@/hooks/crm/useCompanyManagement';
import WidgetCardControls from '@/components/ui/WidgetCardControls.vue';
import { useRouter } from '@/@core/utils/utils';
import CompanyLabel from '@/components/views/crm/company/ui/CompanyLabel.vue';

export default {
  name: 'CompanyDetailsCard',
  props: {
    type: {
      type: String,
      default: 'providers',
    },
  },
  setup(props) {
    const company = inject('company');
    const { deleteCompany } = useCompanyManagement(props.type, null, company);
    const { router } = useRouter();

    const nip = computed(() => {
      if (!company.value.nip) return null;

      const dashed = String(company.value.nip)
        ?.replace(/-/g, '')
        ?.replace(/^(?=[0-9]{1})([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})$/, '$1-$2-$3-$4');

      return dashed;
    });

    return {
      company,
      nip,
      formatDate,
      deleteCompany: () => deleteCompany(() => router.push({
        name: `crm.${props.type}`,
      })),
    };
  },
  computed: {
    companyFetched() {
      // TODO: dokonczyć
      return true;
      // return this.company && !!Object.keys(this.company).length;
    },
  },
  components: {
    CompanyLabel,
    WidgetCard,

    StatusBadge,
    WidgetCardControls,
  },
};
</script>

<style lang="sass" scoped>
.protected-workplace
  position: absolute
  bottom: 35px
  display: flex
  align-items: center
  color: #f4751a

  &-spacer
    margin-bottom: 65px

  .text
    margin-left: 7px

.profile
  display: flex
  align-items: center
  width: 100%

  .details
    width: 100%

    .name
      font-size: 14pt
      font-weight: 600

table
  margin-top: 20px
  line-height: 1.7
  width: 100%

  td:nth-child(1)
    font-weight: 300
    width: 50%
  td:nth-child(2)
    padding-left: 20px
</style>
