<template>
  <widget-card>
    <notes
      v-if="company.notes"
      :items="company.notes"
      create-url="api/crm/company/notes"
      edit-url="api/crm/company/notes/:id"
      target-key="company_id"
    />
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import Notes from '@/components/ui/notes/Notes.vue';
import { inject } from 'vue';

export default {
  name: 'CompanyNotesCard',
  setup() {
    const company = inject('company');

    return {
      company,
    };
  },
  components: {
    WidgetCard,
    Notes,
  },
};
</script>

<style scoped>

</style>
