<template>
  <div>
    <table>
      <tbody>
      <tr>
        <td>ulica:</td>
        <td>
          {{ company.street }} {{ company.street_number }}
        </td>
      </tr>
      <tr>
        <td>kod:</td>
        <td>
          {{ postalCode || '-' }}
        </td>
      </tr>
      <tr>
        <td>miasto:</td>
        <td>
          {{ company.city || '-' }}
        </td>
      </tr>
      <br>
      <tr>
        <td>telefon:</td>
        <td>
          {{ company.phone_number || '-' }}
        </td>
      </tr>
      <tr>
        <td>e-mail:</td>
        <td>
          <a
            v-if="company.email"
            :href="`mailto:${company.email}`"
          >
            {{ company.email }}
          </a>
          <span v-else>-</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'CompanyAddressTable',
  props: {
    company: {
      type: Object,
      default: () => ({
        street: 'Piłsudzkiego',
        postal_code: '88-888',
        city: 'Nydgoszcz',
        street_number: '1',
        locale_number: '12',
        phone_number: 'asdasdas',
        email: 'bydgoszcz.juzek@gmail.com',
      }),
    },
  },
  setup(props) {
    const postalCode = computed(() => {
      if (!props.company.postal_code) return null;

      const dashed = String(props.company.postal_code)
        ?.replace(/-/g, '')
        ?.replace(/^(?=[0-9]{1})([0-9]{2})([0-9]{3})$/, '$1-$2');

      return dashed;
    });

    return { postalCode };
  },
};
</script>

<style lang="sass" scoped>
table
  margin-top: 20px
  line-height: 1.7

  td
    display: block

  td:nth-child(1)
    font-weight: 300
    opacity: 0.6

  td:nth-child(2)
    font-size: 11pt
</style>
