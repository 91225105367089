var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_vm.company.notes ? _c('notes', {
    attrs: {
      "items": _vm.company.notes,
      "create-url": "api/crm/company/notes",
      "edit-url": "api/crm/company/notes/:id",
      "target-key": "company_id"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }