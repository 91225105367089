<template>
  <widget-card>
    <attachments useProps
      :items="attachments"
      :uploading="uploading"

      @add="addAttachment"
      @remove="removeAttachment"
    />
  </widget-card>
</template>

<script>
import { ref } from 'vue';
import ApiClient from '@/services/api';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import {
  AttachmentCreateSuccess,
  AttachmentCreateError,
  AttachmentDeleteSuccess,
  AttachmentDeleteError,
  AttachmentSizeError,
} from '@feedback/module/tasks';
import { useRouter } from '@/@core/utils/utils';
import showToast from '@/libs/toasts';

export default {
  name: 'CompanyAttachmentCard',
  props: {
    files: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const { route } = useRouter();
    const companyId = route.value.params?.id;
    const uploading = ref(false);

    const attachments = ref(props.files);

    const addAttachment = (file) => {
      if (!companyId && companyId !== 0) return;
      uploading.value = true;

      const formData = new FormData();
      formData.append('file', file.file);
      formData.append('mediable_id', companyId);

      ApiClient
        .post('api/crm/company/media', formData)
        .then(({ data }) => {
          showToast(AttachmentCreateSuccess);
          uploading.value = false;
          attachments.value.push(data.data?.file);
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(AttachmentCreateError);
          }
          uploading.value = false;
        });
    };

    const removeAttachment = async (id) => {
      ApiClient
        .delete(`api/crm/company/media/${id}`)
        .then(() => {
          showToast(AttachmentDeleteSuccess);
          attachments.value = attachments.value.filter((a) => a.id !== id);
        })
        .catch(() => showToast(AttachmentDeleteError));
    };

    return {
      uploading,
      addAttachment,
      attachments,
      removeAttachment,
    };
  },
  components: {
    WidgetCard,
    Attachments,
  },
};
</script>

<style scoped>

</style>
