<template>
  <b-card>

    <template #header>
      <b-dropdown
        class="dropdown"
        :text="fullName(selectedPerson)"
        variant="flat-primary"
        v-if="persons.length > 0"
      >
        <b-dropdown-item
          v-for="person in persons" :key="person.id"
          @click="selectedId = person.id"
          >
          {{ fullName(person) }}
        </b-dropdown-item>
      </b-dropdown>

    </template>

    <b-card-text>
      <table v-if="selectedPerson">
        <tbody>
        <tr>
          <td>stanowisko:</td>
          <td>
            {{ selectedPerson.position }}
          </td>
        </tr>
        <tr>
          <td>nr. telefonu:</td>
          <td>
            {{ selectedPerson.phone_number }}
          </td>
        </tr>
        <br>
        <tr>
          <td>e-mail:</td>
          <td>
            <a
              v-if="selectedPerson.email"
              :href="`mailto:${selectedPerson.email}`"
            >
              {{ selectedPerson.email }}
            </a>
            <span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td>RODO:</td>
          <td>
            {{ selectedPerson.rodo ? 'Tak' : 'Nie' }}
          </td>
        </tr>
        </tbody>
      </table>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import { computed, ref } from 'vue';

export default {
  name: 'CompanyAddressContactsTabs',
  setup(props) {
    const selectedId = ref(props.persons?.[0]?.id ?? null);
    const selectedPerson = computed(() => props.persons
      .find(({ id }) => id === selectedId.value) ?? null);

    return { selectedId, selectedPerson };
  },
  props: {
    persons: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    fullName(person = null) {
      if (person) {
        return `${person.forename} ${person.surname}`;
      }

      return 'Osoba kontaktowa';
    },
  },
  components: {
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
  },
};
</script>

<style lang="sass" scoped>
.dropdown::v-deep
  button
    padding-left: 0

    &:hover, &:focus
      background: none !important

table
  line-height: 1.7

  td
    display: block

  td:nth-child(1)
    font-weight: 300
    opacity: 0.6

  td:nth-child(2)
    font-size: 11pt
    margin-bottom: 5px
</style>
