var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "profile"
        }, [_c('div', {
          staticClass: "details"
        }, [!_vm.companyFetched ? _c('content-placeholders', {
          attrs: {
            "rounded": ""
          }
        }, [_c('content-placeholders-heading', {
          attrs: {
            "img": false
          }
        })], 1) : _vm._e(), _vm.companyFetched && _vm.company.company_type && _vm.company.company_type.CLIENT_TYPE ? _c('div', {
          staticClass: "company-type"
        }, [_vm._v(" Klient ")]) : _vm._e(), _vm.companyFetched && _vm.company.company_type && _vm.company.company_type.PROVIDER_TYPE ? _c('div', {
          staticClass: "company-type"
        }, [_vm._v(" Dostawca ")]) : _vm._e(), _vm.companyFetched ? _c('div', {
          staticClass: "name"
        }, [_c('company-label', {
          attrs: {
            "data": _vm.company
          }
        })], 1) : _vm._e()], 1), _c('widget-card-controls', {
          attrs: {
            "editPermission": "crm.company.update",
            "deletePermission": "crm.company.destroy"
          },
          on: {
            "edit": function () {
              return _vm.$router.push({
                name: `crm.${_vm.type}.settings`
              });
            },
            "delete": _vm.deleteCompany
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "action-buttons"
  }, [!_vm.companyFetched ? _c('content-placeholders', {
    staticClass: "mt-2",
    attrs: {
      "rounded": ""
    }
  }, [_c('content-placeholders-text', {
    attrs: {
      "lines": 7
    }
  })], 1) : _c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("status")]), _c('td', [_c('status-badge', {
    attrs: {
      "status_id": _vm.company.company_status_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("region")]), _vm.company.regions_rich ? _c('td', [_vm._v(" " + _vm._s(_vm.company.regions_rich.map(function (region) {
    return region.shortcut;
  }).join(', ')) + " ")]) : _c('td', [_vm._v(" - ")])]), _c('tr', [_c('td', [_vm._v("branża")]), _vm.company.industries_rich ? _c('td', [_vm._v(" " + _vm._s(_vm.company.industries_rich.map(function (industry) {
    return industry.name;
  }).join(', ')) + " ")]) : _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_vm._v("www")]), _c('td', [_vm.company.website ? _c('a', {
    attrs: {
      "href": `//${_vm.company.website}`,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.company.website) + " ")]) : _c('span', [_vm._v("-")])])]), _c('tr', [_c('td', [_vm._v("nip")]), _c('td', [_vm._v(_vm._s(_vm.nip))])]), _c('tr', [_c('td', [_vm._v("regon")]), _c('td', [_vm._v(_vm._s(_vm.company.regon))])]), _c('tr', [_c('td', [_vm._v("krs")]), _c('td', [_vm._v(_vm._s(_vm.company.krs || '-'))])])])]), _vm.company.protected_workplace ? _c('div', {
    staticClass: "protected-workplace-spacer"
  }) : _vm._e(), _vm.company.protected_workplace ? _c('div', {
    staticClass: "protected-workplace"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "ShieldIcon",
      "size": "21"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Miejsce pracy chronionej ")])], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }