var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.persons.length > 0 ? _c('b-dropdown', {
          staticClass: "dropdown",
          attrs: {
            "text": _vm.fullName(_vm.selectedPerson),
            "variant": "flat-primary"
          }
        }, _vm._l(_vm.persons, function (person) {
          return _c('b-dropdown-item', {
            key: person.id,
            on: {
              "click": function ($event) {
                _vm.selectedId = person.id;
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.fullName(person)) + " ")]);
        }), 1) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm.selectedPerson ? _c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("stanowisko:")]), _c('td', [_vm._v(" " + _vm._s(_vm.selectedPerson.position) + " ")])]), _c('tr', [_c('td', [_vm._v("nr. telefonu:")]), _c('td', [_vm._v(" " + _vm._s(_vm.selectedPerson.phone_number) + " ")])]), _c('br'), _c('tr', [_c('td', [_vm._v("e-mail:")]), _c('td', [_vm.selectedPerson.email ? _c('a', {
    attrs: {
      "href": `mailto:${_vm.selectedPerson.email}`
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedPerson.email) + " ")]) : _c('span', [_vm._v("-")])])]), _c('tr', [_c('td', [_vm._v("RODO:")]), _c('td', [_vm._v(" " + _vm._s(_vm.selectedPerson.rodo ? 'Tak' : 'Nie') + " ")])])])]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }