import { ref } from 'vue';
import useCRMApi from '@/hooks/crm/useCRMApi';
import { companyDefault } from '@/hooks/crm/fieldDefaults';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  CreateCompanyError,
  CreateCompanySuccess,
  DeleteCompanyError,
  DeleteCompanySuccess,
  UpdateCompanyError,
  UpdateCompanySuccess,
} from '@/libs/toasts/feedback/module/crm';
import showToast from '@/libs/toasts';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

// TODO: possibly merge with useCompanyDetails
const useCompanyManagement = (type = null, table = null, company) => {
  const modalState = ref({ add: false, edit: false });

  const {
    fields, feedback, clearErrors, areAllFieldsValid, setFieldDefaults,
    assignServerSideErrors, assignFields,
  } = useValidatedForm({
    name: 'required',
    company_status_id: 'required',
    nip: 'required|nip',
    regon: 'required|regon',
    krs: 'integer',
    protected_workplace: '',
    website: '',
    company_type: '',
    industry_ids: 'required',
    branch_ids: 'required',
  });

  const form = ref({});
  const isLoading = ref(false);

  const resetState = () => {
    // form.value = { ...fields, ...companyDefault, company_type: [type] };
    clearErrors();
    setFieldDefaults({ ...companyDefault });

    form.value = { ...fields };
    modalState.value = { add: false, edit: false };
  };

  resetState();

  const createCompany = async (bvEvent) => {
    bvEvent.preventDefault();
    if (!type || !await areAllFieldsValid()) return;

    isLoading.value = true;

    useCRMApi()
      .createCompany({
        ...fields.value,
        company_type: [type],
      })
      .then(() => {
        modalState.value.add = false;
        isLoading.value = false;
        if (table) table.fetchData();
        resetState();

        // TODO: add routing to company details
        showToast(CreateCompanySuccess, { name: fields.value.name });
        RemoteResourceManager.getInstance().notify('crm.provider');
      })
      .catch(({ response }) => {
        assignServerSideErrors(response?.data?.errors);
        isLoading.value = false;

        showToast(CreateCompanyError);
      });
  };

  const updateCompany = async () => {
    if (!await areAllFieldsValid()) return;

    useCRMApi()
      .updateCompany(company.value, {
        ...fields.value,
        company_type: [type.value],
      })
      .then(() => {
        if (table) table.fetchData();

        RemoteResourceManager.getInstance().notify('crm.provider');
        showToast(UpdateCompanySuccess, { name: fields.value.name });
      })
      .catch(({ response }) => {
        assignServerSideErrors(response?.data?.errors);

        showToast(UpdateCompanyError);
      });
  };
  const deleteCompany = async (callback) => {
    const confirmation = await deleteModal({
      title: i18n.t('crm.company.modal.delete.title'),
      text: i18n.t('crm.company.modal.delete.text', {
        name: company.value?.name,
      }),
    });

    if (!confirmation) return false;

    return useCRMApi()
      .deleteCompany(company.value?.id)
      .then(() => {
        RemoteResourceManager.getInstance().notify('crm.provider');
        showToast(DeleteCompanySuccess, { name: company.value?.name });
        callback();
      })
      .catch(() => showToast(DeleteCompanyError, { name: company.value?.name }));
  };

  return {
    form: fields,
    errors: feedback,
    modalState,
    isLoading,

    createCompany,
    updateCompany,
    deleteCompany,
    resetState,
    setFieldDefaults,
    assignFields,
  };
};

export default useCompanyManagement;
