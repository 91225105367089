var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', [_vm.addresses.length > 0 ? _c('b-tabs', _vm._l(_vm.sortedAddresses, function (address) {
    return _c('b-tab', {
      key: address.id,
      attrs: {
        "title": address.name
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [address.is_primary ? _c('span', [_vm._v(" " + _vm._s(`${address.name} - siedziba`) + " ")]) : _c('span', [_vm._v(_vm._s(address.name))])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card-text', [_c('b-container', {
      attrs: {
        "fluid": ""
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "5"
      }
    }, [_c('b-card', {
      attrs: {
        "title": "Dane adresowe"
      }
    }, [_c('company-address-table', {
      attrs: {
        "company": address
      }
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "7"
      }
    }, [address.contacts && address.contacts.length > 0 ? _c('company-address-contacts-tabs', {
      attrs: {
        "persons": address.contacts
      }
    }) : _vm._e()], 1)], 1)], 1)], 1)], 1);
  }), 1) : _c('div', {
    staticClass: "no-address-info"
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "SettingsIcon",
      "size": "36"
    }
  }), _c('div', {
    staticClass: "text"
  }, [_vm._v(" Nie znaleziono informacji kontaktowych dla firmy ")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'crm.providers.settings'
      }
    }
  }, [_vm._v(" Przejdź do ustawień, aby je dodać ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }