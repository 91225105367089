<template>
  <widget-card>
    <b-tabs v-if="addresses.length > 0">
      <b-tab :title="address.name" v-for="address in sortedAddresses" :key="address.id">
        <template #title>
          <span v-if="address.is_primary">
          {{ `${address.name} - siedziba` }}
          </span>
          <span v-else>{{ address.name }}</span>
        </template>
        <b-card-text>
          <b-container fluid>
            <b-row>

              <b-col md="5">
                <b-card title="Dane adresowe">
                  <company-address-table :company="address" />
                </b-card>
              </b-col>

              <b-col md="7">
                <company-address-contacts-tabs
                  v-if="address.contacts && address.contacts.length > 0"
                  :persons="address.contacts"
                />
              </b-col>

            </b-row>
          </b-container>
        </b-card-text>
      </b-tab>
    </b-tabs>

    <div class="no-address-info" v-else>
      <div class="wrapper">
        <feather-icon class="icon" icon="SettingsIcon" size="36" />
        <div class="text">
          Nie znaleziono informacji kontaktowych dla firmy
        </div>
        <router-link :to="{ name: 'crm.providers.settings' }">
          Przejdź do ustawień, aby je dodać
        </router-link>
      </div>
    </div>
  </widget-card>
</template>

<script>
import WidgetCard from '@/components/ui/WidgetCard.vue';
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BTab,
  BTabs,
  BCardText,
} from 'bootstrap-vue';
import CompanyAddressTable from '@/components/views/crm/company/ui/CompanyAddressTable.vue';
import CompanyAddressContactsTabs from '@/components/views/crm/company/ui/CompanyAddressContactsTabs.vue';
import { computed, inject } from 'vue';
import useCompanyContact from '@/hooks/crm/useCompanyContact';

export default {
  name: 'CompanyAddressesCard',
  setup() {
    const companyId = inject('companyId');
    const { addresses, fetch } = useCompanyContact(companyId);

    const sortedAddresses = computed(() => [...addresses.value]
      .sort((a, b) => b.is_primary - a.is_primary));

    fetch();

    return { addresses, sortedAddresses };
  },
  computed: {
    companyAddressFetched() {
      // TODO: dokonczyć
      return true;
      // return this.company && !!Object.keys(this.company).length;
    },
  },
  components: {
    CompanyAddressContactsTabs,
    CompanyAddressTable,
    WidgetCard,

    BContainer,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BCardText,
  },
};
</script>

<style lang="sass" scoped>
.no-address-info
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  height: 100%

  .wrapper
    .icon
      opacity: 0.3
    .text
      font-size: 12pt
      font-weight: 500
      opacity: 0.8
      margin: 20px 0
</style>
