var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table', [_c('tbody', [_c('tr', [_c('td', [_vm._v("ulica:")]), _c('td', [_vm._v(" " + _vm._s(_vm.company.street) + " " + _vm._s(_vm.company.street_number) + " ")])]), _c('tr', [_c('td', [_vm._v("kod:")]), _c('td', [_vm._v(" " + _vm._s(_vm.postalCode || '-') + " ")])]), _c('tr', [_c('td', [_vm._v("miasto:")]), _c('td', [_vm._v(" " + _vm._s(_vm.company.city || '-') + " ")])]), _c('br'), _c('tr', [_c('td', [_vm._v("telefon:")]), _c('td', [_vm._v(" " + _vm._s(_vm.company.phone_number || '-') + " ")])]), _c('tr', [_c('td', [_vm._v("e-mail:")]), _c('td', [_vm.company.email ? _c('a', {
    attrs: {
      "href": `mailto:${_vm.company.email}`
    }
  }, [_vm._v(" " + _vm._s(_vm.company.email) + " ")]) : _c('span', [_vm._v("-")])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }